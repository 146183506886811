<template>
  <v-card class="mb-2" v-if="mode === 'development'">
    <v-card-text>
      <span class="font-weight-black primary--text">Published Artist:</span>
      <true-false :value="isPublishedArtist"></true-false>|
      <span class="ml-2 font-weight-black primary--text">Custom Artist:</span>
      <true-false :value="isCustomArtist"></true-false>|
      <span class="ml-2 font-weight-black primary--text">Customer:</span>
      <true-false :value="isCustomer"></true-false>|
      <span class="ml-2 font-weight-black primary--text">Admin:</span>
      <true-false :value="isAdmin"></true-false>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data () {
    return {
      mode: process.env.NODE_ENV
    }
  },
  computed: mapState({
    ...mapGetters([
      'isAdmin',
      'isCustomer',
      'isCustomArtist',
      'isPublishedArtist']),
  }),
  created () {},
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>
